<template>
  <div class="dialog">
    <div class="dialog-header">
      <div class="row">
        <div class="col-10 my-auto">
          <p class="dialog-title text-uppercase mb-0">Change Password</p>
        </div>
        <div class="col-2 my-auto">
          <img class="close-button"
               src="../../assets/images/close.png"
               alt="close-button"
               @click="$emit('close')" />
        </div>
      </div>
    </div>
    <div class="dialog-content"
         style="overflow-y: auto;"
         v-bind:style="{height: contentHeightOverride}">
      <b-form @submit.prevent="changePassword">
        <b-input-group prepend="Current Password"
                       v-if="user.is_default_password === false">
          <b-form-input id="currentPassword"
                        type="password"
                        placeholder="Current Password"
                        aria-describedby="CurrentPassword"
                        @input="$v.currentPassword.$touch()"
                        v-model="currentPassword"
                        :state="!$v.currentPassword.$error"
                        autocomplete="off">
          </b-form-input>
          <b-form-invalid-feedback id="currentPassword">
            <span v-if="!$v.currentPassword.minLength">
              Current Password must have at least
              {{$v.currentPassword.$params.minLength.min}} characters
            </span>
            <span v-if="!$v.currentPassword.correctPassword &&
              $v.currentPassword.minLength">
              Incorrect Current Password
            </span>
          </b-form-invalid-feedback>
        </b-input-group>

        <b-input-group prepend="New Password">
          <b-form-input id="password"
                        type="password"
                        placeholder="New Password"
                        aria-describedby="Password"
                        @input="$v.password.$touch()"
                        v-model="password"
                        :state="!$v.password.$error"
                        autocomplete="off">
          </b-form-input>
          <b-form-invalid-feedback id="Password">
            <span v-if="!$v.password.minLength">
              Password must have at least
              {{$v.password.$params.minLength.min}} characters
            </span>
            <span v-if="!$v.password.validateNewPassword">
              Current and New Password must not be the same.
            </span>
          </b-form-invalid-feedback>
        </b-input-group>

        <b-input-group prepend="Confirm Password">
          <b-form-input id="confirmPassword"
                        type="password"
                        placeholder="Confirm Password"
                        aria-describedby="ConfirmPassword"
                        @input="$v.confirmPassword.$touch()"
                        v-model="confirmPassword"
                        :state="!$v.confirmPassword.$error"
                        autocomplete="off">
          </b-form-input>
          <b-form-invalid-feedback id="ConfirmPassword">
            <span v-if="!$v.confirmPassword.minLength">
              Confirm Password must have at least
              {{$v.confirmPassword.$params.minLength.min}} characters
            </span>
            <span v-if="!$v.confirmPassword.sameAsPassword &&
              $v.confirmPassword.minLength">
                Passwords must be the same
              </span>
          </b-form-invalid-feedback>
        </b-input-group>

        <!-- Update Button -->
        <div class="text-center">
          <b-button class="btn btn-success btn-h3-small"
                    type="submit"
                    :disabled="$v.$invalid">
            Update
          </b-button>
          &nbsp;
          <b-button class="btn btn-danger btn-h3-small"
                    type="button"
                    @click="$emit('close')">
            Cancel
          </b-button>
        </div>
      </b-form>

      <!-- Loading -->
      <loading :active.sync="isLoading"
               :is-full-page="true"
               background-color="#000"
               color="#068bcd"
               :opacity="0.5"
               :width="70"
               :height="70">
        <rainbow-loader></rainbow-loader>
      </loading>
    </div>
  </div>
</template>

<script>
  import { required, minLength, sameAs } from 'vuelidate/lib/validators';
  import BreakpointMixin from '@/mixins/BreakpointMixin';
  import { mapActions } from 'vuex';

  const Loading = () => import('vue-loading-overlay');
  const RainbowLoader = () => import('@/components/shared/RainbowLoader');

  export default {
    name   : 'ChangePassword',
    mixins : [
      BreakpointMixin,
    ],
    data() {
      return {
        isLoading       : false,
        currentPassword : null,
        password        : null,
        confirmPassword : null,
        correctPassword : true,
        user            : this.$store.getters['user/user'],
      }
    },
    computed : {
      inputCurrentPass() {
        return this.currentPassword;
      },
    },
    components : {
      Loading,
      RainbowLoader,
    },
    watch : {
      inputCurrentPass() {
        this.correctPassword = true;
        this.checkCurrentPassword();
      },
    },
    validations() {
      if (this.user.is_default_password === true) {
        return {
          password : {
            minLength : minLength(5),
            required,
          },
          confirmPassword : {
            minLength      : minLength(5),
            sameAsPassword : sameAs('password'),
            required,
          },
        }
      }
      
      return {
        currentPassword : {
          minLength : minLength(5),
          required,
          correctPassword() {
            return this.correctPassword;
          },
        },
        password : {
          minLength : minLength(5),
          required,
          validateNewPassword() {
            if (this.currentPassword === this.password)
              return false;
            
            return true;
          },
        },
        confirmPassword : {
          minLength      : minLength(5),
          sameAsPassword : sameAs('password'),
          required,
        },
      }
    },
    methods : {
      ...mapActions({
        'getUpdatedUser' : 'user/getUpdatedUser',
      }),

      // Verify Current Password
      // eslint-disable-next-line no-undef
      checkCurrentPassword : _.debounce(function() {
        this.$http.get('api/user/pass/check', {
          params : {
            password : this.currentPassword,
          },
        }).then(() => {
          this.correctPassword = true;
        }).catch(() => {
          this.correctPassword = false;
        });
      }, 1500),

      // Change Password
      changePassword() {
        this.isLoading = true;
        this.$http.put('api/user', {
          'updateFields' : {
            password : this.password,
          },
        }).then(() => {
          this.getUpdatedUser();
          const that = this;
          setTimeout(() => {
            that.$emit('close');
            that.isLoading = false;
          }, 1000);
          that.$notify({
            group : 'notif',
            type  : 'success',
            title : 'Success',
            text  : 'Updated Password Successfully!',
          });
        }).catch(() => {
          this.isLoading = false;
          this.$notify({
            group : 'notif',
            type  : 'error',
            title : 'Failed!',
            text  : 'Oops! Something went wrong.',
          });
        });
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/components/modals/change-password";
</style>